import { parsePhoneNumberWithError } from "libphonenumber-js";

export class PhoneNumberValidator {
  static isValid(value: string): boolean {
    try {
      return parsePhoneNumberWithError(value).isValid() && value.startsWith("+");
    } catch (e) {
      const err = e as any;
      if (err.message == "TOO_SHORT") {
        throw Error("The string supplied is too short to be a phone number");
      } else if (err.message == "TOO_LONG") {
        throw Error("The string supplied is too long to be a phone number");
      } else if (err.message == "INVALID_COUNTRY") {
        throw Error("The country supplied is not valid");
      } else if (err.message == "NOT_A_NUMBER") {
        throw Error("The string supplied is not a phone number");
      }
      throw Error(err.message);
    }
  }
}
