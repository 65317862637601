<template>
  <div class="d-flex align-center custom-input-component-container">
    <div
      v-if="
        pComponentData.visible &&
        pComponentData.componentType == componentTypeTextField
      "
      class="text-width"
    >
      <v-text-field
        class="custom-text-field-component"
        :class="{ 'normal-color': pDisabled }"
        v-model="value"
        :type="textFieldType"
        :rules="[validationResult]"
        :loading="pLoading && pComponentData.editable"
        :label="pComponentData ? pComponentData.display : ''"
        :disabled="!pComponentData.editable || pDisabled"
        @input="notifyChanged()"
        :min="pComponentData.minIntegerValue"
        :max="pComponentData.maxIntegerValue"
      ></v-text-field>
    </div>
    <div
      v-else-if="
        pComponentData.visible &&
        pComponentData.componentType == componentTypeAutocomplete
      "
      class="text-width"
    >
      <v-autocomplete
        :class="{ 'normal-color': pDisabled }"
        class="custom-autocomplete-component"
        ref="autocompleteComponent"
        placeholder="Start typing to search…"
        :label="pComponentData ? pComponentData.display : ''"
        v-model="value"
        :items="matchedValues"
        item-text="text"
        item-value="value"
        no-data-text="No data matches your search criteria"
        :rules="[validationResult]"
        :loading="pLoading && pComponentData.editable"
        :disabled="!pComponentData.editable || pDisabled"
        @keydown="loadFilteredValues($event)"
        @change="notifyChanged()"
        @blur="clearValueIfAutocompleteIsBlank()"
      ></v-autocomplete>
    </div>
    <div
      v-else-if="
        pComponentData.visible &&
        pComponentData.componentType == componentTypeCombobox
      "
      class="text-width"
    >
      <v-combobox
        :class="{ 'normal-color': pDisabled }"
        class="custom-combobox-component"
        ref="comboboxComponent"
        :label="pComponentData ? pComponentData.display : ''"
        v-model="value"
        :items="pComponentData.comboboxItems"
        item-text="text"
        item-value="value"
        :hint="value ? value.description : ''"
        persistent-hint
        :rules="[validationResult]"
        :loading="pLoading && pComponentData.editable"
        :disabled="!pComponentData.editable || pDisabled"
        @input="notifyChanged()"
        @change="notifyChanged()"
      >
        <template v-if="value && value.description" v-slot:item="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div
                class="combobox-item-style"
                v-bind="attrs"
                v-on="on"
              >
                {{ item.text }}
              </div>
            </template>
            <span>{{ item.description }}</span>
          </v-tooltip>
        </template>
      </v-combobox>
    </div>

    <div
      v-else-if="
        pComponentData.visible &&
        pComponentData.componentType == componentTypeTextarea
      "
      class="text-width"
    >
      <v-textarea
        auto-grow
        class="custom-textarea-component"
        :class="{ 'normal-color': pDisabled }"
        v-model="value"
        :rules="[validationResult]"
        :loading="pLoading && pComponentData.editable"
        :label="pComponentData ? pComponentData.display : ''"
        :disabled="!pComponentData.editable || pDisabled"
        @input="notifyChanged()"
      ></v-textarea>
    </div>

    <div
      v-else-if="
        pComponentData.visible &&
        pComponentData.componentType == componentTypeCheckbox
      "
      class="text-width"
    >
      <v-checkbox
        :class="{ 'normal-color': pDisabled }"
        class="custom-checkbox-component"
        :label="pComponentData ? pComponentData.display : ''"
        v-model="value"
        item-text="text"
        item-value="value"
        :rules="[validationResult]"
        :loading="pLoading && pComponentData.editable"
        :disabled="!pComponentData.editable || pDisabled"
        @change="notifyChanged()"
      />
    </div>

    <div
      v-else-if="pComponentData.componentType == doubleFieldsType"
      class="text-width d-flex justify-space-between double-fields-component"
    >
      <div
        v-for="item in displayItemParams"
        :key="item.value"
        class="text-not-full-width fields-in-double-fields-component"
      >
        <CustomInputComponent
          :pComponentData="item"
          :pValue="pEditedItem[item.value]"
          :pLoading="pLoading"
          :pDisabled="pDisabled"
          ref="doubleFieldsComponent"
          @onValueChanged="notifyChanged()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import debounce from "lodash/debounce";
import Vue from "vue";
import {
  EMAIL_REGEX,
  INTEGER_REGEX,
  TEXT_FIELD_COMPONENT_TYPE,
  AUTOCOMPLETE_COMPONENT_TYPE,
  COMBOBOX_COMPONENT_TYPE,
  TEXTAREA_COMPONENT_TYPE,
  DOUBLE_FIELDS_TYPE,
  getAllDataFromCustomInputComponents,
  STRING_VALUE_TYPE,
  EMAIL_VALUE_TYPE,
  PHONE_VALUE_TYPE,
  AUTOCOMPLETE_VALUE_TYPE,
  COMBOBOX_VALUE_TYPE,
  INSTANT_VALUE_TYPE,
  INTEGER_VALUE_TYPE,
  CHECKBOX_VALUE_TYPE,
  CHECKBOX_COMPONENT_TYPE,
  dateTimeMomentFormat, logAndExtractMessage, dismissAction,
} from "../../utils/utils";
import { PhoneNumberValidator } from "@/services/phone-number-validator";

export default {
  name: "InputComponent",

  components: {
    CustomInputComponent: () => import("./InputComponent"),
  },

  props: {
    pEditedItem: Object,
    pComponentData: Object,
    pValue: [String, Object, Boolean, Number],
    pDisabled: {
      type: Boolean,
      default: false,
    },
    pLoading: {
      type: Boolean,
      default: false,
    },
    pDisabledParams: Array,
  },

  data() {
    return {
      componentTypeTextField: TEXT_FIELD_COMPONENT_TYPE,
      componentTypeAutocomplete: AUTOCOMPLETE_COMPONENT_TYPE,
      componentTypeCombobox: COMBOBOX_COMPONENT_TYPE,
      componentTypeCheckbox: CHECKBOX_COMPONENT_TYPE,
      componentTypeTextarea: TEXTAREA_COMPONENT_TYPE,
      doubleFieldsType: DOUBLE_FIELDS_TYPE,

      integerValueType: INTEGER_VALUE_TYPE,
      instantValueType: INSTANT_VALUE_TYPE,
      dateTimeMomentFormat,

      value: this.pValue,
      matchedValues:
        this.pValue && this.pValue.id
          ? [
            {
              text: this.pValue.name ? this.pValue.name : "",
              value: this.pValue,
            },
          ]
          : [],
    };
  },

  computed: {
    isChanged() {
      if (!this.value) {
        return this.pValue !== this.value;
      }

      if (this.pComponentData.type == AUTOCOMPLETE_VALUE_TYPE) {
        return (this.pValue || {}).id !== (this.value || {}).id;
      }

      if (this.pComponentData.type == COMBOBOX_VALUE_TYPE) {
        return this.pValue !== this.value.value;
      }

      return this.pValue !== this.value;
    },

    valueInField() {
      if (!this.value) return undefined;

      if (this.pComponentData.type == AUTOCOMPLETE_VALUE_TYPE) {
        return this.value.name;
      }

      if (this.pComponentData.type == COMBOBOX_VALUE_TYPE) {
        return this.value.text || this.value;
      }

      return this.value;
    },

    validationResult() {
      if (!this.pComponentData.editable || !this.pComponentData.visible) {
        return true;
      }

      if (this.pComponentData.type == CHECKBOX_VALUE_TYPE) {
        return this.checkboxValidation;
      }

      if (this.pComponentData.type == AUTOCOMPLETE_VALUE_TYPE) {
        return this.autocompleteValidation;
      }

      if (this.pComponentData.type == COMBOBOX_VALUE_TYPE) {
        return this.comboboxValidation;
      }

      if (this.pComponentData.type == INTEGER_VALUE_TYPE) {
        return this.integerValidation;
      }

      if (this.pComponentData.type == INSTANT_VALUE_TYPE) {
        return this.instantValueValidation;
      }

      if (this.pComponentData.type == STRING_VALUE_TYPE) {
        return this.stringValueValidation;
      }

      if (this.pComponentData.type == EMAIL_VALUE_TYPE) {
        return this.emailValidation;
      }

      if (this.pComponentData.type == PHONE_VALUE_TYPE) {
        return this.phoneValidation;
      }

      return false;
    },

    checkboxValidation() {
      return (
        this.value
          || (!this.value && !this.pComponentData.required)
          || "Required"
      );
    },

    autocompleteValidation() {
      return (
        !!this.valueInField
          || (!this.valueInField && !this.pComponentData.required)
          || "Required"
      );
    },

    comboboxValidation() {
      const validComboboxItems = this.pComponentData.comboboxItems
        .map((item) => item.text);

      if (
        validComboboxItems.includes(this.valueInField)
          || (!this.valueInField && !this.pComponentData.required)
      ) {
        // Some components have a description field.
        // If we accepting a valid users-written value
        // we don`t see a description for that value
        // because it is a String, but not an Object
        this.changeValidTextToValidObject();
        return true;
      }
      return "Not valid value";
    },

    integerValidation() {
      if (this.valueInField) {
        const minValue = this.pComponentData.minIntegerValue;
        const maxValue = this.pComponentData.maxIntegerValue;

        if (minValue !== undefined && (Number(this.valueInField) < minValue)) {
          return `Value must not be less than ${minValue}`;
        }
        if (maxValue !== undefined && (Number(this.valueInField) > maxValue)) {
          return `Value must not be greater than ${maxValue}`;
        }
      }
      return (
        this.maxLengthValidation
          || INTEGER_REGEX.test(this.valueInField)
          || (!this.valueInField && !this.pComponentData.required)
          || "Not valid value"
      );
    },

    instantValueValidation() {
      return this.valueInField().format(dateTimeMomentFormat);
    },

    maxLengthValidation() {
      return this.value
          && this.pComponentData.maxLength
          && this.value.toString().length > this.pComponentData.maxLength
          && `${this.pComponentData ? this.pComponentData.display : "Value"} is too long.`;
    },

    stringValueValidation() {
      return (
        this.maxLengthValidation
          || !!this.valueInField
          || (!this.valueInField && !this.pComponentData.required)
          || "Required"
      );
    },

    emailValidation() {
      return (
        this.maxLengthValidation
          || EMAIL_REGEX.test(this.valueInField)
          || (!this.valueInField && !this.pComponentData.required)
          || "Must be a valid email address"
      );
    },

    phoneValidation() {
      try {
        return (
          this.maxLengthValidation
            || (this.valueInField && PhoneNumberValidator.isValid(this.valueInField))
            || (!this.valueInField && !this.pComponentData.required)
            || "Must be a valid phone number"
        );
      } catch (e) {
        return e.message;
      }
    },

    savedValue() {
      if (this.pComponentData.type == CHECKBOX_VALUE_TYPE) {
        return this.value ? this.value : false;
      }

      if (!this.value) {
        return null;
      }

      if (this.pComponentData.type == AUTOCOMPLETE_VALUE_TYPE) {
        return this.value.id;
      }

      if (this.pComponentData.type == COMBOBOX_VALUE_TYPE) {
        return this.value.value;
      }

      return this.value;
    },

    displayItemParams() {
      return this.pComponentData.fieldComponents.map((param) => ({
        ...param,
        editable: !this.pDisabledParams.includes(param.value),
      }));
    },

    textFieldType() {
      if (this.pComponentData.type === INTEGER_VALUE_TYPE) return "number";
      if (this.pComponentData.type === INSTANT_VALUE_TYPE) return "instant";
      return "text";
    },
  },

  methods: {
    changeValidTextToValidObject() {
      this.value = this.pComponentData.comboboxItems
        .find((item) => item.text == this.valueInField || item.value == this.value);
    },

    clearValueIfAutocompleteIsBlank() {
      if (
        this.$refs.autocompleteComponent
          && (!this.$refs.autocompleteComponent.internalSearch
              || (this.$refs.autocompleteComponent.internalSearch
                  && !this.$refs.autocompleteComponent.internalSearch.trim()))
      ) {
        this.value = { id: "" };
        this.notifyChanged();
      }
    },

    removeChanges() {
      if (this.pComponentData.componentType == this.componentTypeCombobox) {
        this.changeValidTextToValidObject();
      } else if (this.pComponentData.componentType == this.componentTypeAutocomplete) {
        this.value = this.pValue;
        if (this.value) {
          this.matchedValues = [{
            text: this.value.name,
            value: this.value,
          }];
        }
      } else if (this.pComponentData.type == INSTANT_VALUE_TYPE) {
        this.value = moment(this.pValue)
          .format(dateTimeMomentFormat);
      } else {
        this.value = this.pValue;
      }
    },

    saveNewValue() {
      if (this.$refs.doubleFieldsComponent) {
        return getAllDataFromCustomInputComponents(
          this.$refs.doubleFieldsComponent,
        );
      }

      return {
        [this.pComponentData.value]: this.savedValue,
      };
    },

    loadFilteredValues: debounce(function (event) {
      if (event.key.length != 1 && event.key != "Backspace") return; // Filter buttons like AppKey

      let storeRef;
      if (this.pComponentData.value == "host") {
        storeRef = "hostModule/loadFilteredHost";
      }

      const obj = {};
      if (this.$refs.autocompleteComponent.internalSearch) {
        obj.name = this.$refs.autocompleteComponent.internalSearch;
      }

      this.$store.dispatch(storeRef, obj)
        .then((data) => {
          this.matchedValues = data.map((item) => ({
            text: item.name,
            value: item,
          }));
        }).catch(e => {
          Vue.toasted.error(logAndExtractMessage(e), { ...dismissAction });
        });
    }, 300),

    notifyChanged() {
      this.$emit("onValueChanged");
    },
  },

  mounted() {
    if (this.pComponentData.type == INSTANT_VALUE_TYPE) {
      this.value = moment(this.pValue)
        .format(dateTimeMomentFormat);
    }

    if (this.pComponentData.componentType == this.componentTypeCombobox) {
      // If the field is disabled, we will see the default value,
      // because this field is not validated
      // ex: SUPER_ADMIN does not convert to Super Admin
      // And we converting the value on mounted
      this.changeValidTextToValidObject();
    }
  },
};
</script>

<style scoped lang="scss">
.text-width {
  width: 100%;
}

.text-not-full-width {
  width: 47%;
}

.combobox-item-style {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  padding-left: 15px;
}
</style>
<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.custom-input-component-container {
  .normal-color {
    label,
    input,
    textarea {
      color: rgba(map-get($shades, "black"), 0.87) !important;
    }
  }

  .v-messages:not(.error--text) {
    .v-messages__message.message-transition-enter-to {
      color: var(--v-textColor-base) !important;
      font-style: italic;
      margin-bottom: 15px;
    }
  }
}
</style>
